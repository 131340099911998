import { DatadogLoggingService } from '@edx/frontend-logging';

class CustomDatadogLoggingService extends DatadogLoggingService {
  beforeSend(event, context) {
    // To avoid the following error in the console, "ResizeObserver loop completed with undelivered notifications,"
    // which is caused by autofill password extensions such as LastPass, note that it started occurring after
    // the upgrade from react-router v5 to v6.
    if (
      event.type === "error" &&
      event.error.message.includes(
        "ResizeObserver loop completed with undelivered notifications"
      )
    ) {
      return false;
    }
    return super.beforeSend(event, context);
  }
}

const config = {
  loggingService: CustomDatadogLoggingService,
};

export default config;
